<template>
  <footer
    class="bg-black bg-opacity-90 md:flex md:flex-wrap md:justify-between md:items-start md:py-4"
  >
    <!--Copyright-->
    <div class="p-2 md:w-1/3 md:mt-0 md:order-1">
      <div class="mb-2 p-0 text-center align-middle text-gray-300 md:text-left md:mb-0 md:ml-4">
        &copy; {{ currentYear() }}
        <router-link to="/" exact class="text-orange-500 hover:text-orange-600">
          ZJ Dynamics S.A de C.V.
        </router-link>
        All rights reserved.
      </div>
    </div>
    <!--Links-->
    <div class="p-2 md:w-1/3 md:mt-0 md:order-3">
      <div class="mb-2 text-center text-gray-300 text-2xl md:text-right md:mb-0">
        <!--Linkedin-->
        <a
          href="https://www.linkedin.com/company/zj-dynamics/"
          rel="noopener"
          target="_blank"
          class="px-2 py-0 md:mr-4 text-gray-300 hover:text-white"
        >
            <SvgIcon icon="brands/linkedin-in" color="text-gray-300" hover="hover:text-white" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import SvgIcon from "./general/SvgIcon";

export default {
  name: "Footer",
  components: {
    SvgIcon,
  },
  methods: {
    currentYear() {
      const options = { year: "numeric" };
      return new Date().toLocaleDateString("en", options);
    },
  },
};
</script>