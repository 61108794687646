<template>
<!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 1003.95 376.48" style="enable-background:new 0 0 1003.95 376.48;" xml:space="preserve"
      class="w-full zj-dynamics-logo">
<g>
	<g>
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="314.4163" y1="85.4993" x2="392.4152" y2="163.4983">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st0" d="M390.7,146.63v18.58h-74.21v-14.68l41.78-49.59h-40.72V82.36h71.37v14.68l-41.78,49.59H390.7z"/>
		<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="403.6552" y1="89.7601" x2="463.7375" y2="149.8424">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st1" d="M402.18,154.68l12.78-15.15c4.38,5.68,8.99,8.64,14.32,8.64c6.75,0,10.41-4.14,10.41-12.07v-35.63h-28.64
			V82.36h51.84v52.31c0,21.54-11.13,32.2-31.96,32.2C418.86,166.87,408.57,162.61,402.18,154.68z"/>
	</g>
	<g>
		<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="305.2427" y1="207.5406" x2="390.0621" y2="256.5111">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st2" d="M316.49,188.06h37.31c26.87,0,45.29,16.19,45.29,41.07c0,24.88-18.42,41.07-45.29,41.07h-37.31V188.06z
    M352.86,254.6c16.31,0,26.99-9.74,26.99-25.46c0-15.72-10.68-25.46-26.99-25.46H335.5v50.93H352.86z"/>
		<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="394.7745" y1="222.0749" x2="456.9185" y2="257.9537">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st3" d="M475.48,207.07l-28.51,67c-6.1,15.25-14.78,19.83-26.17,19.83c-6.45,0-13.49-2.11-17.6-5.75l6.69-13.03
			c2.82,2.47,6.69,3.99,10.33,3.99c5.05,0,7.86-2.23,10.33-7.86l0.23-0.59l-27.34-63.6h18.89l17.72,42.83l17.84-42.83H475.48z"/>
		<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="476.0705" y1="220.2843" x2="551.8099" y2="264.0125">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st4" d="M548.24,234.06v36.14h-18.31v-33.33c0-10.21-4.69-14.9-12.79-14.9c-8.8,0-15.14,5.4-15.14,17.01v31.21h-18.3
			v-63.13h17.48v7.39c4.93-5.4,12.32-8.33,20.89-8.33C536.97,206.13,548.24,214.82,548.24,234.06z"/>
		<linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="559.3838" y1="221.2809" x2="627.0325" y2="260.338">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st5" d="M621.34,234.18v36.02h-17.13v-7.86c-3.4,5.75-9.97,8.8-19.24,8.8c-14.78,0-23.58-8.21-23.58-19.13
			c0-11.15,7.86-18.89,27.11-18.89h14.55c0-7.86-4.69-12.44-14.55-12.44c-6.69,0-13.61,2.23-18.19,5.87l-6.57-12.79
			c6.92-4.93,17.13-7.63,27.22-7.63C610.19,206.13,621.34,215.05,621.34,234.18z M603.03,250.25v-6.45h-12.56
			c-8.57,0-11.26,3.17-11.26,7.39c0,4.58,3.87,7.63,10.33,7.63C595.64,258.82,600.92,256,603.03,250.25z"/>
		<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="636.2628" y1="211.0989" x2="743.5959" y2="273.0677">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st6" d="M745.25,234.06v36.14h-18.31v-33.33c0-10.21-4.58-14.9-11.97-14.9c-8.21,0-13.96,5.28-13.96,16.54v31.68
			h-18.31v-33.33c0-10.21-4.34-14.9-11.97-14.9c-8.1,0-13.85,5.28-13.85,16.54v31.68h-18.3v-63.13h17.48v7.28
			c4.69-5.4,11.73-8.21,19.83-8.21c8.8,0,16.31,3.4,20.77,10.33c5.04-6.45,13.26-10.33,22.76-10.33
			C734.46,206.13,745.25,214.82,745.25,234.06z"/>
		<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="751.0012" y1="226.7827" x2="792.0667" y2="250.4918">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st7" d="M762.38,207.07h18.31v63.13h-18.31V207.07z"/>
		<linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="798.513" y1="221.3336" x2="857.2871" y2="255.2668">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st8" d="M793.71,238.64c0-19.01,14.67-32.5,35.2-32.5c13.26,0,23.7,5.75,28.28,16.08l-14.2,7.63
			c-3.4-5.98-8.45-8.68-14.2-8.68c-9.27,0-16.54,6.45-16.54,17.48c0,11.03,7.28,17.48,16.54,17.48c5.75,0,10.8-2.58,14.2-8.68
			l14.2,7.74c-4.58,10.09-15.02,15.96-28.28,15.96C808.38,271.14,793.71,257.65,793.71,238.64z"/>
		<linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="863.3155" y1="222.6828" x2="919.7544" y2="255.2679">
			<stop  offset="0" style="stop-color:#D9D9D9"/>
			<stop  offset="0.0932" style="stop-color:#E1E1E1"/>
			<stop  offset="0.3551" style="stop-color:#F2F2F2"/>
			<stop  offset="0.6423" style="stop-color:#FCFCFC"/>
			<stop  offset="1" style="stop-color:#FFFFFF"/>
		</linearGradient>
		<path class="st9" d="M862.35,264.34l6.1-13.14c5.63,3.64,14.2,6.1,21.94,6.1c8.45,0,11.62-2.23,11.62-5.75
			c0-10.33-38.14,0.23-38.14-24.99c0-11.97,10.8-20.42,29.22-20.42c8.68,0,18.3,1.99,24.29,5.51l-6.1,13.03
			c-6.22-3.52-12.44-4.69-18.19-4.69c-8.21,0-11.74,2.58-11.74,5.87c0,10.79,38.14,0.35,38.14,25.23c0,11.73-10.91,20.07-29.8,20.07
			C879.02,271.14,868.22,268.21,862.35,264.34z"/>
	</g>
	
		<linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="5878.7949" y1="6137.6631" x2="5860.3027" y2="6119.1709" gradientTransform="matrix(0 -1 1 0 -5356.8833 6059.0225)">
		<stop  offset="0" style="stop-color:#E8501E"/>
		<stop  offset="1" style="stop-color:#EC6C1D"/>
	</linearGradient>
	<path class="st10" d="M762.29,180.23v18.49h18.49v-18.49H762.29z M774.02,191.96h-4.98v-4.98h4.98V191.96z"/>
</g>
<g>
	
		<linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="499.3235" y1="648.4193" x2="581.6535" y2="505.8195" gradientTransform="matrix(-1 0 0 -1 719.595 806.1183)">
		<stop  offset="0" style="stop-color:#EC6C1D"/>
		<stop  offset="1" style="stop-color:#F18D19"/>
	</linearGradient>
	<polygon class="st11" points="85.35,188.06 272.86,188.06 272.86,269.93 85.35,269.93 	"/>
	
		<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="538.6097" y1="668.0851" x2="538.6097" y2="480.4301" gradientTransform="matrix(-1 0 0 -1 664.9134 750.3644)">
		<stop  offset="0" style="stop-color:#EC6C1D"/>
		<stop  offset="1" style="stop-color:#F18D19"/>
	</linearGradient>
	<polygon class="st12" points="85.35,82.28 167.26,82.28 167.26,269.93 85.35,269.93 	"/>
	
		<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="7091.895" y1="6245.3765" x2="7009.1455" y2="6162.6274" gradientTransform="matrix(0 -1 1 0 -5972.4312 7174.3433)">
		<stop  offset="0" style="stop-color:#E8501E"/>
		<stop  offset="1" style="stop-color:#EC6C1D"/>
	</linearGradient>
	<path class="st13" d="M190.28,82.37v82.91h82.59V82.37H190.28z M243.05,135.14h-22.81v-22.81h22.81V135.14z"/>
	
		<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="3811.3276" y1="6613.3203" x2="3729.4177" y2="6531.4102" gradientTransform="matrix(0 -1 1 0 -6446.0615 3999.3521)">
		<stop  offset="0" style="stop-color:#E8501E"/>
		<stop  offset="1" style="stop-color:#EC6C1D"/>
	</linearGradient>
	<polygon class="st14" points="167.26,188.02 167.26,269.93 85.35,269.93 85.35,188.02 	"/>
</g>
</svg>

</template>

<style type="text/css">
	.st0{fill:url(#SVGID_1_);}
	.st1{fill:url(#SVGID_2_);}
	.st2{fill:url(#SVGID_3_);}
	.st3{fill:url(#SVGID_4_);}
	.st4{fill:url(#SVGID_5_);}
	.st5{fill:url(#SVGID_6_);}
	.st6{fill:url(#SVGID_7_);}
	.st7{fill:url(#SVGID_8_);}
	.st8{fill:url(#SVGID_9_);}
	.st9{fill:url(#SVGID_10_);}
	.st10{fill:url(#SVGID_11_);}
	.st11{fill:url(#SVGID_12_);}
	.st12{fill:url(#SVGID_13_);}
	.st13{fill:url(#SVGID_14_);}
	.st14{fill:url(#SVGID_15_);}
	.st15{fill:url(#SVGID_16_);}
	.st16{fill:url(#SVGID_17_);}
	.st17{fill:url(#SVGID_18_);}
	.st18{fill:url(#SVGID_19_);}
	.st19{fill:url(#SVGID_20_);}
	.st20{fill:url(#SVGID_21_);}
	.st21{fill:url(#SVGID_22_);}
	.st22{fill:url(#SVGID_23_);}
	.st23{fill:url(#SVGID_24_);}
	.st24{fill:url(#SVGID_25_);}
	.st25{fill:url(#SVGID_26_);}
	.st26{fill:url(#SVGID_27_);}
	.st27{fill:url(#SVGID_28_);}
	.st28{fill:url(#SVGID_29_);}
	.st29{fill:url(#SVGID_30_);}
	.st30{fill:url(#SVGID_31_);}
	.st31{fill:url(#SVGID_32_);}
	.st32{fill:url(#SVGID_33_);}
	.st33{fill:url(#SVGID_34_);}

	.zj-dynamics-logo {
		animation: 2s appear;
		margin: auto;
	}

	@keyframes appear {
		0%, 20% {
			opacity: 0;
		}
	}
</style>
