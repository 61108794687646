<template>
  <div id="app" class="antialiased text-gray-900">
    <div class="flex flex-wrap items-center min-h-screen">
      <!--Content-->
      <div class="w-full mx-auto md:w-2/3">
        <router-view></router-view>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Footer,
  },
};
</script>
