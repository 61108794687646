<template>
  <div class="home">
    <Logo />
  </div>
</template>

<script>
import Logo from '../components/general/Logo';

export default {
  name: 'Home',
  components: {
    Logo,
  }
}
</script>
